/* ==========================================================================
   Tabs
   ========================================================================== */

/* Basic positioning styles shared by primary and secondary tabs. */
%tabs {
  @include clearfix;
  background-image: linear-gradient(to bottom, $tabs-border 1px, transparent 1px);
  /* IE 9 and earlier don't understand gradients. */
  list-style: none;
  border-bottom: 1px solid $tabs-border \0/ie;
  margin: rhythm(1) 0;
  padding: 0 2px;
  white-space: nowrap;
}
%tabs__tab {
  float: left; /* LTR */
  margin: 0 3px;
}
%tabs__tab-link {
  border: 1px solid #e9e9e9;
  border-right: 0;
  border-bottom: 0;
  display: block;
  //@include adjust-leading-to(1);
  text-decoration: none;
}

/* Primary tabs. */
.tabs.primary {
  @extend %tabs;
}
.tabs.primary li {
  @extend %tabs__tab;
  //@include border-top-radius(4px);
  border-top-radius:4px;
  border: 1px solid $tabs-border;
  border-bottom-color: transparent;
  /* IE 9 and earlier don't understand gradients. */
  border-bottom: 0 \0/ie;
}
.tabs.primary li.active {
  //@extend .tabs-primary__tab;
  border-bottom-color: $tabs-container-bg;
}

// We use 3 placeholder styles to prevent @extend from going selector crazy.
%tabs-primary__tab-link {
  @extend %tabs__tab-link;

   border-top-radius:4px;

  transition:background-color .3s;
  color: #333;
  background-color: #dedede;
  letter-spacing: 1px;
  padding: 0 1em;
  text-align: center;
}
%tabs-primary__tab-link-is-hover {
  background-color: #e9e9e9;
  border-color: #f2f2f2;
}
%tabs-primary__tab-link-is-active {
  background-color: transparent;
  //@include filter-gradient(rgba(#e9e9e9, 1), rgba(#e9e9e9, 0));
 
  background-image: linear-gradient(rgba(#e9e9e9, 1), rgba(#e9e9e9, 0));
  border-color: #fff;
}

.tabs.primary a {
  @extend %tabs-primary__tab-link;

  &:hover,
  &:focus {
    @extend %tabs-primary__tab-link-is-hover;
  }
  &:active {
    @extend %tabs-primary__tab-link-is-active;
  }
}
.tabs.primary a.active {
  @extend %tabs-primary__tab-link;
  @extend %tabs-primary__tab-link-is-active;
}

/* Secondary tabs. */
.tabs-secondary {
  @extend %tabs;
  font-size: .9em;
  /* Collapse bottom margin of ul.primary. */
  margin-top: -(rhythm(1));
}
.tabs-secondary__tab,
.tabs-secondary__tab.is-active {
  @extend %tabs__tab;
  margin: rhythm(1)/2 3px;
}

// We use 3 placeholder styles to prevent @extend from going selector crazy.
%tabs-secondary__tab-link {
  @extend %tabs__tab-link;
  border-radius: .75em;
  transition: background-color .3s;
  text-shadow: 1px 1px 0 #fff;
  background-color: #f2f2f2;
  color: #666;
  padding: 0 .5em;
}
%tabs-secondary__tab-link-is-focus {
  background-color: #dedede;
  border-color: #999;
  color: #333;
}
%tabs-secondary__tab-link-is-active {
  text-shadow: 1px 1px 0 #333;
  background-color: #666;
  border-color: #000;
  color: #fff;
}

a.tabs-secondary__tab-link {
  @extend %tabs-secondary__tab-link;

  &:hover,
  &:focus {
    @extend %tabs-secondary__tab-link-is-focus;
  }
  &:active {
    @extend %tabs-secondary__tab-link-is-active;
  }
}
a.tabs-secondary__tab-link.is-active {
  @extend %tabs-secondary__tab-link;
  @extend %tabs-secondary__tab-link-is-active;
}