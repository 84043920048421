// ==========================================================================
// Header
// ==========================================================================

#name-and-slogan {
	padding: .5em 2.5% .5em 5%;
	float: left;
	@include breakpoint($bp-narrow) {
		width: 82.5%;
	}
	#site-name {
		font-family: 'YaleDesign';
		margin: 0;
		line-height: 1;
		font-weight: normal;
		font-size: 2em;
		@include breakpoint($bp-intermediate-two) {
			font-size: 1.7em;
		}
		@include breakpoint($bp-narrow) {
			font-size: 1.6em;
		}
		a {
			color: $dark-green;
		}
	}	
}

.header-fes {
	position: absolute;
	top: 0;
	width: 100%;
	margin: 0;
	padding: .5em 5%;
	background-color: $light-green;
	p {
		font-size: .7em;
		font-family: 'YaleDesign';
		text-transform: uppercase;
		margin: 0;
		line-height: 1;
		a {
			color: $white;
			span {
				text-transform: none;
				font-size: 1.5em
			}
		}
	}
}

#block-search-form {
	margin: 0;
	float: right;
	max-height: 0;
	display: none;
	overflow: hidden;
	transition: max-height 250ms;
	font-size: .8em;
	.search-form {
		margin: 0;
		.form-text {
			padding: .5em 1em;
		}
		input.form-submit {
			padding: .5em 1em;			
		}
	}
	&.search-reveal {
		max-height: 50px;
		display: block;
	}
	@include breakpoint($bp-narrow) {
		display: block;
		width: 90%;
		max-height: 100px;
		margin: 0 5%;
		.search-form {
			border-bottom: 1px solid #e6e6e6;
			margin-bottom: 2em;
			overflow: hidden;
			input.form-submit {
				background: url('/sites/all/themes/hixon/images/icon-search.svg') no-repeat;
				width: 16px;
				height: 16px;
				overflow: hidden;
				display: inline-block;
				text-indent: -9999px;
				border: 0;
				margin-bottom: 1em;
			}
			.form-text { 
				width: 89%;
				padding: .5em 0;
				text-align: center;
				border: none;
			}
		}
	}
}
