/* ==========================================================================
   Typography
   ========================================================================== */


/**
 * Root html element
 * (set defaults for root font size and line height)
 */
html {
  font-family: $font-family-default; 
  font-size: 100% * ($base-font-size-value / 16); 
  line-height: $base-line-height-ratio ; 
}

/**
 * Headers
 */

h1 {
	font-size: 3em;
	line-height: 1;
	margin: .5em 0;
}

h2 {

}

h3 {

}

h4 {

}

h5 {

}

h6 {

}

/**
 * Paragraph
 */

p {

}

/**
 * Lists
 */

ul {

}

ol {

}

/**
 * Quotes
 */

blockquote {
  
}