/* ==========================================================================
   Home Feature
   ========================================================================== */

/* Primary home feature */

#block-views-homepage-feature-block-1 {
	background-color: $dark-green;
	position: relative;
	color: $white;
	margin-bottom: 0;
	overflow: hidden;
	.group-banner-box {
		position: absolute;
		bottom: 0;
		z-index: 2;
		left: 5%;
	}
	.field-homepage-description {
		line-height: 1.2;
	}
	.field-homepage-image { 
		z-index: 1;
		position: relative;
		@include breakpoint($bp-middle-and-wide) {
			float: right;
			&:after {
				left: 0;
				top: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba($dark-green, 0);
				border-left-color: $dark-green;
				border-width: 30px;
				margin-top: -30px;
			}
		}
		img {
			width: 100%;
		}
		@include breakpoint($bp-wide) {
			width: 65%;
			& ~ div {
				width: 25%;
			}
		}
		@include breakpoint($bp-intermediate-two) {
			width: 55%;
			& ~ div {
				width: 35%;
			}
		}
		@include breakpoint($bp-intermediate-one) {
			width: 50%;
			& ~ div {
				width: 40%;
			}
		}
		@include breakpoint($bp-narrow) {
			width: 100%;
			& ~ div {
				padding: 0 5%;
				padding-top: 2em;
				left: 0;
				text-shadow: 1px 1px 2px $color-shadow;
				/* Safari 4, Chrome 1-9, iOS 3.2-4.3, Android 2.1-3.0 */
				background-image:
					-webkit-gradient(from(transparent), to($dark-green));
				/* Safari 5.1, iOS 5.0-6.1, Chrome 10-25, Android 4.0-4.3 */
				background-image:
					-webkit-linear-gradient(transparent, $dark-green);
				/* Firefox 3.6 - 15 */
				background-image:
					-moz-linear-gradient(transparent, $dark-green);
				/* Opera 11.1 - 12 */
				background-image:
					-o-linear-gradient(transparent, $dark-green);
				/* Opera 15+, Chrome 25+, IE 10+, Firefox 16+, Safari 6.1+, iOS 7+, Android 4.4+ */
				background-image: 
					linear-gradient(transparent, $dark-green);
			}
		}
	}
	a {
		color: $white;
		margin: 1em 0;
		display: inline-block;
		text-transform: uppercase;
		padding: .5em;
		border: 3px solid $light-green;
		font-weight: bolder;
		&:hover {
			border: 3px solid $green;
		}
	}
	h2 {
		font-size: 1.5em;
		font-family: $font-family-sans;
		line-height: 1.2em;
		margin-top: 0;
		padding-top: 1em;
		margin-bottom: .5em;
		padding-bottom: .5em;
	}

	.field-homepage-description {
		font-size:1.35em !important;
	}
}

/* Secondary home feature */

#block-views-homepage-feature-block {
	background-color: $pale-green;
	padding: 2em 5%;
	overflow: hidden;
	border-bottom: 1px solid $very-light-gray;
	article {
		@include breakpoint($bp-middle-and-wide) {
			width: 48%;
			&.views-row-1 {
				float: left;
			}
			&.views-row-2 {
				float: right;
			}
		}
		@include breakpoint($bp-narrow) {
			clear: both;
			margin-bottom: 2em;
		}
		.field-homepage-image {
			width: 20%;
			float: left;
			& ~ div {
				margin-left: 25%;
				margin-bottom: .5em;
			}
		}
		h3 {
			margin: 0;
			line-height: 1.1;
		}
		.field-homepage-link {
			text-transform: uppercase;
			font-size: .8em;
			font-weight: bolder;
		}
	}
}

/* Other homepage blocks */

.home-block {
	margin: 0 5%;
	border-top: 1px solid $very-light-gray;
	@include breakpoint($bp-middle-and-wide) {
		width: 28%;
		float: left;
		&.home-yew {
			margin-right: 3%
		}
		&.home-events {
			margin: 0;
		}
		&.home-uri {
			margin-left: 3%
		}
	}
	h2 {
		text-transform: uppercase;
		font-size: 1.2em;
	}
	h3 {
		margin: 0;
	}
	p {
		a {
			text-transform: uppercase;
			font-weight: bolder;
		}
	}
	img {
		width: 100%;
	}
	&.home-events {
		article {
			margin-bottom: 2em;
		}
		p {
			color: $medium-gray;
			margin: .5em 0;
			text-transform: uppercase;
			font-size: .9em;
			&.event-type {
				font-weight: normal;
			}
			&.event-date {
				font-weight: bolder;

			}
		}
	}
}

/* Other homepage overrides */

.front {
	.page__title {
		display: none;
	}
}