/* ==========================================================================
   Fellow
   ========================================================================== */

/* Fellow node view */

.node-type-fellow {
	.field-fellow-photo {
		@include breakpoint($bp-middle-and-wide) {
			float: left;
			width: 25%;
			margin-right: 5%;
			& ~ blockquote {
				margin-left: 30%;
			}
		}
	}
	blockquote {
		font-family: 'YaleDesign';
		font-style: italic;
		font-size: 1.4em;
	}
	.fellow-year {
		font-weight: bold;
		font-size: 1.3em;
	}	
	.field-label--inline--wrapper {
		font-weight: bolder;
		.field-label--inline {
			font-weight: normal;
		}
	}
	h2 {
		border-top: 1px solid $very-light-gray;
		padding-top: 1em;
		clear: both;
	}
	.fellow-paper-poster-wrapper {
		div {
			display: inline-block;
			a {
				font-weight: bolder;
				padding-left: 16px;
				background-image: image-url('icon-file-link.svg');
				background-repeat: no-repeat;
				background-size: auto 60%;
				background-position: 0 40%;
				&:hover, 
				&:focus {
					background-image: image-url('icon-file-dark.svg');
				}
			}
			&.field-fellow-poster-rewrite, &.field-fellow-presentation-rewrite {
				margin-left: 1em;
				padding-left: 1em;
				border-left: 1px solid $green;
			}
		}

	}
	.field-fellow-paper-rewrite {
		
		a {
			font-weight: bolder;
			padding-left: 16px;
		}
	}
	.field-fellow-poster-rewrite, .field-fellow-presentation-rewrite {
		display: inline-block;
	}
}

/* Fellows pages */

.view-fellows {
	h2 {
		clear: both;
		border-top: 1px solid $very-light-gray;
		padding-top: .5em;
		margin-top: 1em;
		margin-bottom: .5em;
	}
	article {
		position: relative;
		margin-bottom: 2em;
		@include breakpoint($bp-wide) {
			float: left;
			width: 24%;
			margin-right: 1%;
		}
		@include breakpoint($bp-middle) {
			float: left;
			width: 32%;
			margin-right: 1%;
		}
		.fellow-caption {
			position: absolute;
			bottom: 0;
			width: 100%;
			background-color: $green-background;
			padding: .1em;
			text-align: center;
			a {
				color: $white;
				font-weight: bolder;
			}
			.fellow-year {
				margin: 0;
				font-family: 'Yale-design';
				font-style: italic;
				font-size: .9em;
				color: $white;
			}
		}
	}
}

.view-advisors {
	h2 {
		clear: both;
		border-top: 1px solid $very-light-gray;
		padding-top: .5em;
		margin-top: 1em;
		margin-bottom: .5em;
	}
}

/* Fellows footer mosaic */

.fellows-mosaic {
	clear: both;
	.view-fellows {
		article {
			position: relative;
			margin-right: 0;
			margin-bottom: 0;
			@include breakpoint($bp-wide) {
				width: 11.11%;
			}
			@include breakpoint($bp-middle) {
				width: 16.66%;
			}
			@include breakpoint($bp-narrow) {
				width: 33.33%;
				float: left;
			}
			.fellow-mosaic-caption {
				  position: absolute;
				  width: 100%;
				  height: 100%;
				  top: 0;
				  padding: 1em;
				  background-color: $green-background;
				  color: $white;
				  opacity: 0;
				  transition: opacity 0.5s;
				  .fellow-name {
				  	font-weight: bold;
				  	margin-bottom: 0;
				  	line-height: 1;
				  }
				  .fellow-year {
				  	font-style: italic;
				  	margin-top: 0;
				  }
				  .fellow-link {
				  	position: absolute;
					bottom: 0;
					text-transform: uppercase;
					font-size: .8em;
				  }
				  a {
				  	color: $white;
				  	&:hover {
					  	color: $white;				  		
				  	}
				  }
			}
			&:hover {
				.fellow-mosaic-caption {
					opacity: 1;
				}
			}
		}
	}
}
