/* ==========================================================================
   Blocks
   ========================================================================== */

/* Block wrapper */
.block {
  margin-bottom: 1em;
}


