/* ==========================================================================
   Page Regions
   define colors, backgrounds for main page wrappers
   ========================================================================== */

html {
	background-color: $dark-green;
}

body {
	margin: 0;
	background-color: $white;
}

.region-page {

}

/**
 * Header
 */
.region-header {
	padding-top: 3em;
	@extend %clearfix;
}

/**
 * Main Content Region
 */
.region-main {
	clear: both;
	.region-main-inner {
		overflow: hidden;
	}
}

.not-front {
	.region-main { 
		.region-main-inner {
			padding: 0 5%;
		}
		&:before {
		   	height: 100px;
			width: 100%;
			background-position: center;
			background-color: $dark-green;
			padding-left: 5%;
			color: $white;
			text-transform: uppercase;
			font-size: 20px;
			display: block;
			padding-top: 40px;
			letter-spacing: 3px;
		}
	}
}

.region-main { 
	&:before {
		.section-about & {
			content: 'About'; 
			background-image: image-url('header-bg-kids.jpg');
		}
		.section-research & {
			content: 'Research'; 
			background-image: image-url('header-bg-traffic.jpg');
		}
		.section-teaching & {
			content: 'Teaching'; 
			background-image: image-url('header-bg-cans.jpg');
		}
		.section-practice & {
			content: 'Practice'; 
			background-image: image-url('header-bg-tree-planting.jpg');
		}
	}
}

.sidebar-second {
	@include breakpoint($bp-middle-and-wide) {
		.region-content {
			width: 65%;
			float: left;
		}
		.sidebars {
			width: 25%;
			float: right;
		}
	}
}
/**
 * Footer
 */
.region-footer {

}

