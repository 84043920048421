/* ==========================================================================
   Box sizing
   default set in global variables
   ========================================================================== */

/* apply a natural box layout model to all elements, but allowing components to change */
html {
 
  box-sizing:border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

