/* ==========================================================================
   Media
   ========================================================================== */

/**
 * Images and Captions
 */

img {
  /* Remove border when inside `a` element in IE 8/9. */
  border: 0;
 

  /* Suppress the space beneath the baseline */
  vertical-align: bottom;

  /* Responsive images */
  max-width: 100%;
  height: auto;
  width: auto;
}


/* Embedded images using WYSIWYG and Picture module */
picture {
  display: block;
  position: relative;
  margin-bottom: 0.5em;
  &[data-picture-align="left"] {
    @extend %float-left;
  }
  &[data-picture-align="right"] {
    @extend %float-right;
  }
  &[data-picture-align="center"]  {
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &[data-picture-mapping="full_width"] {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  &[data-picture-mapping="half_width"] {
    width: 50%;
  }
  &[data-picture-mapping="quarter_width"] {
    width: 25%;
  }
  img {
    display: block;
  }
}

/* Alternate for Hixon (no Picture module) using cKeditor styles */
img.floatright {
  @extend %float-right;
}

img.floatleft {
  @extend %float-left;
}

img.center {
  margin-left: auto;
  margin-right: auto;
}

/* Photo Captions */
figcaption,
.caption {
  font-size: 0.75em;
  margin: .5em 0;
  font-style: italic;
}