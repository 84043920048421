// ==========================================================================
// Footer
// ==========================================================================

.footer-address {
	background: $dark-green;
	color: $white;
	font-family: 'YaleDesign';
	padding: 2em 5%;
	margin: 0;
	p {
		margin: 0;
		line-height: 1.2;
		span {
			float: left;
			font-size: 2.5em;
			padding: 0 .5em .6em 0;
			border-right: 1px solid $very-dark-green;
			margin-right: .5em;
		}
	}
}