/* ==========================================================================
   Conference
   ========================================================================== */

.node-type-conference {
	.page__title {
		display: none;
	}
}

.group-conference-banner {
	background-color: $dark-green;
	position: relative;
	color: $white;
	margin-bottom: 0;
	overflow: hidden;
	margin-top: 2em;
	.field-conference-image { //need to fix with responsive image
		z-index: 1;
		@include breakpoint($bp-middle-and-wide) {
			float: right;
		}
		img {
			width: 100%;
		}
		@include breakpoint($bp-wide) {
			width: 65%;
			& ~ div {
				margin-right: 70%;
				margin-left: 5%;
			}
		}
		@include breakpoint($bp-intermediate-two) {
			width: 55%;
			& ~ div {
				margin-right: 60%;
				margin-left: 5%;
			}
		}
		@include breakpoint($bp-intermediate-one) {
			width: 50%;
			& ~ div {
				margin-right: 55%;
				margin-left: 5%;
			}
		}
		@include breakpoint($bp-narrow) {
			width: 100%;
			& ~ div {
				z-index: 2;
				margin: 0;
				padding: 0 5%;
				position: absolute;
				bottom: 0;
				/* Safari 4, Chrome 1-9, iOS 3.2-4.3, Android 2.1-3.0 */
				background-image:
					-webkit-gradient(from(transparent), to($dark-green));
				/* Safari 5.1, iOS 5.0-6.1, Chrome 10-25, Android 4.0-4.3 */
				background-image:
					-webkit-linear-gradient(transparent, $dark-green);
				/* Firefox 3.6 - 15 */
				background-image:
					-moz-linear-gradient(transparent, $dark-green);
				/* Opera 11.1 - 12 */
				background-image:
					-o-linear-gradient(transparent, $dark-green);
				/* Opera 15+, Chrome 25+, IE 10+, Firefox 16+, Safari 6.1+, iOS 7+, Android 4.4+ */
				background-image: 
					linear-gradient(transparent, $dark-green);
			}
		}
	}
	p {
		font-size: 1.2em;
		font-weight: normal;
	}
	a {
		color: $white;
		margin: 1em 0;
		display: inline-block;
		text-transform: uppercase;
		padding: .5em;
		border: 3px solid $light-green;
		font-weight: bolder;
		&:hover {
			border: 3px solid $green;
		}
	}
	h2 {
		margin-bottom: 0;
	}
	h3 {
		font-size: 1.4em;
		line-height: 1.2em;
		margin-top: 0;
		margin-bottom: .5em;
		padding-bottom: .5em;
		border-bottom: 3px solid $light-green;
	}
}

.group-presented {
	@include breakpoint($bp-middle-and-wide) {
		width: 35%;
		float: right;
		h3 {
			font-size: 1.4em;
		}
		& + div {
			margin-right: 40%;
			h3 {
				font-size: 1.4em;
			}
		}
	}
}

.group-conference-program-poster {
		div {
			display: inline-block;
			a {
				font-weight: bolder;
				padding-left: 16px;
				background-image: image-url('icon-file-link.svg');
				background-repeat: no-repeat;
				background-size: auto 60%;
				background-position: 0 40%;
				&:hover, 
				&:focus {
					background-image: image-url('icon-file-dark.svg');
				}
			}
			&.field-conference-poster-rewrite {
				margin-left: 1em;
				padding-left: 1em;
				border-left: 1px solid $green;
			}
		}
}

.conference-agenda {
	border-top: 1px solid $very-light-gray;
	margin-top: 2em;
	clear: both;
	.view-conference-agenda-item {
		.views-row {
			padding: .5em;
			margin-bottom: .5em;
			overflow: hidden;
			&.views-row-even {
				background-color: $very-light-gray;
			}
		}
		h2 {
			background-color: $light-green;
			color: $white;
			font-size: 1.3em;
			padding: .5em;
		}
		h4 {
			margin-bottom: 0;
			margin-top: 0;
		}
		h5 {
			margin-bottom: 0;
			margin-top: .2em;
			text-transform: uppercase;
			font-size: .8em;
			@include breakpoint($bp-middle-and-wide) {
				position: absolute;
				top: 0;
				left: -16%;
				text-align: right;
				width: 15%;
			}
		}
		.field-agenda-time, .field-event-time {
			@include breakpoint($bp-middle-and-wide) {
				float: left;
				width: 13%;
				& ~ div {
					margin-left: 15%;
				}
			}
			font-size: .8em;
			font-weight: bold;
		}
		.field-conference-person-rewrite {
			p {
				margin-top: .5em;
				margin-bottom: 0;
				font-style: italic;
				.conference-agenda-person-name {
					font-style: normal;
				}
			}
		}
		fieldset {
			border: 0;
			padding: 0;
			&.collapsible {
				position: static;
			}
			.fieldset-wrapper {
				@include breakpoint($bp-middle-and-wide) {
					//padding-top: 2em;
				}
			}
			legend {
				font-size: .8em;
				font-weight: bold;
				text-transform: uppercase;
				@include breakpoint($bp-middle-and-wide) {
					//position: absolute;
					//top: 2em;
				}
				a {
					padding-left: 16px;
					background-image: image-url('icon-user-link.svg');	
					background-repeat: no-repeat;
					background-size: auto 60%;
					background-position: 0 40%;
					&:hover, 
					&:focus {
						background-image: image-url('icon-user-dark.svg');
					}
				}
			}
			.field-person-bio, .field-person-abstract {
				margin-top: 0;
			}
			&.group-abstract {
				legend {
					@include breakpoint($bp-middle-and-wide) {
						//left: 7em;
						//margin-left: 1em;
						//padding-left: 1em;
						//border-left: 1px solid $green;
					}
					a {
						background-image: image-url('icon-file-link.svg');
						&:hover, 
						&:focus {
							background-image: image-url('icon-file-dark.svg');
						}
					}
				}
			}
		}
		.field-agenda-people {
			position: relative;
		}
		.field-person-photo {
			@include breakpoint($bp-middle-and-wide) {
				float: left;
				width: 15%;
				padding-bottom: 1em;
				& ~ * {
					margin-left: 17%;
					margin-top: 0;
				}
			}
		}
	}
}

.conference-list {
	article {
		clear: both;
		margin-bottom: 2em;
		overflow: hidden;
	}
	.field-conference-image {
		float: left;
		width: 20%;
		& ~ * {
			margin-left: 22%;
		}
	}
	h3 {
		margin: 0;
	}
	.field-event-time {
		font-style: italic;
		margin-bottom: 1em;
	}
}

.node-type-lecture-series {
	.group-conference-banner {
		h3 {
			margin-top: 2em !important;
		}
		p {
			font-size: 1em;
		}
	}
}