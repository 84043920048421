/* ==========================================================================
   Forms
   ========================================================================== */


/* Wrapper for a form element (or group of form elements) and its label. */
.form-item {
  margin: 0 0 1em;

  /* Pack groups of checkboxes and radio buttons closer together. */
  .form-checkboxes & ,
  .form-radios & {
    /* Drupal core uses "0.4em 0". */
    margin: 0;
  }

  /* Form items in a table. */
  tr & {
    margin: 0;
  }

  /* Highlight the form elements that caused a form submission error. */
  input.error,
  textarea.error,
  select.error {
    border: 1px solid #c00;
  }

  /* The descriptive help text (separate from the label). */
  .description {
    font-size: 0.85em;
  }
}

.form-type-radio,
.form-type-checkbox {
  .description {
    margin-left: 2.4em;
  }
}

/* The part of the label that indicates a required field. */
.form-required {
  color: #c00;
}

/* Labels for radios and checkboxes. */
label.option {
  display: inline;
  font-weight: normal;
}

/* Submit button in Hixon style */

input.form-submit {
  background: $dark-green;
  border: 3px solid $light-green;
  color: $white;
  text-transform: uppercase;
  padding: .5em 2em;
  margin-bottom: 1em;
  &:hover {
    background: $light-green;
    border: 3px solid $dark-green;
  }
}
