/* ==========================================================================
   Navigation
   ========================================================================== */

/**
 * Main menu
 */

.region-navigation {
  @extend %clearfix;
  z-index: 99;
  .block-title {
    @include element-invisible;
  }
  &.nav-collapse {
    @include breakpoint($bp-middle-and-wide) {
      overflow: visible;
    }
  }
  @include breakpoint($bp-middle-and-wide) {
    float: right;
    padding: .8em 5% .2em 2.5%;
  }
  @include breakpoint($bp-intermediate-one) {
    float: left;
    padding-left: 5%;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li.level-1 {
      display: inline;
      text-transform: uppercase;
      font-weight: bold;
      margin-right: 2em; 
      position: relative;
      @include breakpoint($bp-intermediate-two) {
        margin-right: 1.5em; 
        font-size: .9em;
      }
      & > a {
        padding-bottom: .5em;
        border-bottom: 2px solid transparent;
        text-transform: uppercase;
        font-weight: bold;
        &:hover {
          border-bottom: 2px solid $green;
        }
      }
      ul {
        display: none;
        position: absolute;
        background-color: rgba($white,.9);
        border: 1px solid $green;
        font-size: .9em;
        left: 0;
        width: 12em;
        font-weight: normal;
        li {
          padding: .2em .5em;
        }
      }
      @include breakpoint($bp-middle-and-wide) {
        &:hover {
          & > ul {
            display: block;
            z-index: 1;
          }
        }
      }
      &.last {
        display: none;
        margin: 0;
        @include breakpoint($bp-middle-and-wide) {
          display: inline;
          a {
            background: url('/sites/all/themes/hixon/images/icon-search.svg') no-repeat;
            width: 16px;
            height: 16px;
            overflow: hidden;
            display: inline-block;
            text-indent: -9999px;
            position: relative;
            top: .2em;
            &:hover {
              border-bottom: 2px solid transparent;
            }
          }
        }
      }
    }
  }
}

/**
 * Main menu responsive nav using js library: http://responsive-nav.com/
 */

.nav-toggle {
  @include breakpoint($bp-narrow) {
    background-image: image-url('icon-menu.svg');
    background-repeat: no-repeat;
    background-position: 10px;
    height: 33px;
    width: 10%;
    float: right;
    margin-right: 5%;
    margin-top: .3em;
    text-indent: -9999px;
    overflow: hidden;
    border-left: 1px solid $dark-green;
    &.active {
      background-image: image-url('icon-menu-close.svg');
    }
  }
}

.region-navigation {
  @include breakpoint($bp-narrow) {
    width: 100%;
    clear: both;
    ul {
      overflow: hidden;
      border-top: 1px solid $very-light-gray;
      margin: 0 5%;
      width: 90%;
      li {
        margin: 0;
        width: 50%;
        display: block;
        float: left;
        text-align: center;
        border-bottom: 1px solid $very-light-gray;
        padding: .5em 0;
        a {
          border-bottom: none;
          &:hover {
            border-bottom: none;
          }
        }
        &:nth-child(odd) {
          border-right: 1px solid $very-light-gray;
        }
      }
      
    }
  }
}

/**
 * Subpage sidebar menu
 */

.menu-block-sidebar {
  margin-top: 6em;
  padding-top: 1em;
  border-top: 1px solid $very-light-gray;
  h2 {
    font-size: 1.1em;
    text-transform: uppercase;
    line-height: 1;
    margin: 0;
  }
  .menu {
    list-style: none;
    margin: .5em 0;
    padding: 0;
    .level-2 {
      &.active-trail {
        &:before {
          content: "▪ ";
        }
      }
      & > a {
        font-size: 1.1em;
        &.active-trail {
          font-style: italic;
        }
      }
      .level-3 {
        margin-left: 2em;
        a { 
          &.active-trail {
            font-weight: bold;
            &:before {
              content: "";
            }
          }
        }
      }
    }
  }
}

/**
 * Breadcrumb navigation.
 */
.breadcrumb {
  ol {
    margin: 0;
    padding: 0;
  }
  li {
    display: inline;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}