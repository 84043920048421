// ==========================================================================
// = Global Variables
// ==========================================================================


/**
 * Compass
 */
//@import "compass";

/**
 * Other Sass gems
 */
// Breakpoint
// http://breakpoint-sass.com/
//@import "breakpoint";


/**
 * Define Compass Browser Support
 * @link http://compass-style.org/reference/compass/support/
 */

// $browser-minimum-versions: (
//   'ie': "8"
// );

/**
 * Breakpoint
 * @link https://github.com/at-import/breakpoint
 */
 @import '../node_modules/breakpoint-sass/stylesheets/breakpoint';



/**
 * CSS layout debugging
 * @link http://pesticide.io/
 * @type boolean
 */

$pesticide-debug              : false;


// Color Scheme
// example based on the official Sass styleguide
// replace/adapt as needed
// =============

// Descriptive colors:

$pale-green                   : #f8fff7;
//$light-green                : #8f9c6c;
$light-green				  : #5e6941;
$green                        : #5f712d;
$dark-green                   : #384e34;
$very-dark-green              : #283826;
$green-background             : rgba($dark-green, 1);

$very-light-gray              : #e6e6e6;
$light-gray                   : #979797;
//$medium-gray                  : #7f7f7f;
$medium-gray				: #333;
$gray                         : #4d4d4d;
$dark-gray                    : #333;
$text-gray                    : #2d2d2d;
$charcoal                     : #222;

$white                        : #fff;
$black                        : #000;

// Main color palette
$color-primary                : $green;
$color-secondary              : $dark-green;
$color-accent                 : $light-green;
$color-shadow                 : rgba($black, .125);
$color-note                   : #666;

// Common colors
$color-background             : $white;
$color-background-shade       : $pale-green;
$color-background-invert      : $dark-green;
$color-border                 : $light-green;
$color-text                   : $text-gray;
$color-text-weak              : $medium-gray;
$color-text-strong            : $charcoal;
$color-text-heading           : $charcoal;
$color-text-invert            : $white;
$color-text-strong-invert     : $color-background;

// Links
$color-link                   : $color-primary;
$color-link-hover             : $color-secondary;
$color-link-visited           : $color-primary;


// Selections
$color-selection              : #b3d4fc;

// Messages
$color-message                : #f4ecbb;
$color-success                : #2cde2c;
$color-warning                : #cf8600;
$color-important              : #d00;
$color-notice                 : #66b;


// Tabs
$tabs-container-bg: #fff;
$tabs-border: #bbb;


/**
 * Base font size in used in _mixins.scss
 * @type number
 */

$base-font-size-value         : 16;
$base-font-size               : $base-font-size-value * 1px ;

/**
 * Base line-height in used in _mixins.scss
 * @type number
 */

$base-line-height-ratio       : 1.4;
$base-line-height             : ( $base-font-size-value * $base-line-height-ratio ) * 1px;


// Font Stacks
// ===========

/**
 * (optional) URL for Google Fonts import
 * @type string
 */

// $google-fonts-url          : 'http://fonts.googleapis.com/css?family=Source+Sans+Pro|Source+Code+Pro';

/**
 * (optional) names of webfonts imports
 * @type array
 */

// $fonts                     : 'FONTNAME', 'FONTNAME', 'FONTNAME', 'FONTNAME';

/**
 * define font stack used for sans-serifs
 * @type string
 */

$font-family-sans             : sans-serif;

/**
 * define font stack used for serifs
 * @type string
 */

$font-family-serif            : serif;

/**
 * define font stack used for monospaced copy
 * @type string
 */

$font-family-monospace        : 'Inconsolata', "Menlo", Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console',
                                'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L',
                                Monaco, 'Courier New', Courier, monospace;

/**
 * define font stack used for headings
 * @requires {variable} $font-family-serif
 * @type string
 */

$font-family-display          : $font-family-serif;

/**
 * define font stack used for paragraphs
 * @requires {variable} font-family-sans
 * @type string
 */

$font-family-default          : $font-family-sans;

// Font Weights
// ============

$font-weight-light            : 300;
$font-weight-normal           : 400;
$font-weight-semibold         : 700;
$font-weight-bold             : 900;


// Breakpoints
// Define your own breakpoints to suit the design
// @link http://breakpoint-sass.com/
// ============

$breakpoint-to-ems: true;
$breakpoint-no-query-fallbacks: true;

$bp-narrow-end: (630/16) * 1em; 
$bp-middle-start: (631/16) * 1em; 
$bp-middle-end: (1099/16) * 1em; 
$bp-wide-start: (1100/16) * 1em; 

$bp-intermediate-end: (865/16) * 1em; 

$bp-narrow: max-width $bp-narrow-end;
$bp-middle: $bp-middle-start $bp-middle-end;
$bp-wide: $bp-wide-start, 'no-query' '.lt-ie9';

$bp-intermediate-one: $bp-middle-start $bp-intermediate-end;
$bp-intermediate-two: $bp-intermediate-end $bp-middle-end;

$bp-narrow-and-middle: max-width $bp-middle-end;
$bp-middle-and-wide: $bp-middle-start, 'no-query' '.lt-ie9';


// CSS Box Model
// @link: http://www.paulirish.com/2012/box-sizing-border-box-ftw/
// @type string
// ===========
$box-sizing-default: border-box;

/**
 * Define z-indexes for various elements
 * @link http://www.sitepoint.com/using-sass-maps/
 * @type number
 */

// z-index reference
$zindex: (
  modal                       : 9000,
  overlay                     : 8000,
  dropdown                    : 7000,
  header                      : 6000,
  footer                      : 5000
);

