/* ==========================================================================
   Staff
   ========================================================================== */

/* Staff page view */
.view-staff-taxonomy {
	overflow: hidden;
	h2 {
		margin: .5em 0;
	}
	ul {
		list-style: none;
		padding: 0;
		.views-field-name {
			a {
				font-family: 'proxima-nova';
				font-weight: bolder;
				font-size: .9em;
			}
		}
	}
	.staff-name-title {
		font-size: 1.4em;
		border-bottom: 1px solid $very-light-gray;
		padding-bottom: .3em;
		margin-bottom: .3em;
		.staff-name {
			font-weight: bolder;
		}
		.staff-title {
			font-weight: normal;
		}
	}
	@include breakpoint($bp-middle-and-wide) {
		img {
			float: left;
			width: 25%;
			& ~ p {
				margin: .5em 0 .5em 30%;
			}
		}
	}
	& > div > ul > li {
		border-top: 1px solid $very-light-gray;
		margin-top: 1em;
	}
}

