/* ==========================================================================
   YEW (Yale Experimental Watershed)
   ========================================================================== */

/* YEW research issues view block */

.view-research-issues {
	article {
		h4 {
			font-size: 1.2em;
			margin: .5em 0 0 0;
			min-height: 4em;
		}
		p {
			margin-top: 0;
		}
		@include breakpoint($bp-middle-and-wide) {
			float: left;
			margin-right: 5%;
		}
		@include breakpoint($bp-wide) {
			width: 20%;
		}
		@include breakpoint($bp-middle) {
			width: 28%;
		}
	}
}

.page-node-25 {
	h2 {
		clear: both;
	}
}

/* YEW reports */

.reports-list {
	clear: both;
}

.view-reports {
	overflow: hidden;
	article {
		clear: both;
		margin-bottom: 3em;
		overflow: hidden;
		h3 {
			margin-top: 0;
		}
		p {
			margin-top: 0;
		}
		img {
			border: 1px solid $dark-green;
			@include breakpoint($bp-middle-and-wide) {
				float: left;
				margin-right: 5%;
				width: 35%;
			}
		}
		a {
				font-weight: bolder;
				padding-left: 16px;
				background-image: image-url('icon-file-link.svg');
				background-repeat: no-repeat;
				background-size: auto 60%;
				background-position: 0 40%;
				&:hover, 
				&:focus {
					background-image: image-url('icon-file-dark.svg');
				}
		}
		.field-report-author {
			font-style: italic;
			margin: 0;
		}
		.field-report-year {
			font-style: italic;
			margin: 0;
		}
	}
}

.view-css-table {
	article {
		display: table;
		border: 1px solid $light-gray;
		.view-css-table-row {
			display: table-row;
			&:nth-child(even) {
				background-color: $very-light-gray;
			}
			.view-css-table-cell {
				display: table-cell;
				padding: .5em;
				&:first-child {
					text-align: right;
				}
			}
		}
	}
}


.block-quicktabs {
	margin-bottom: 2em;
	.ul.quicktabs-tabs.quicktabs-style-navlist {
		width: 15%;
		li.active {
				a {
					color: $color-text;
				}
				&.active {
				background-color: $very-light-gray;
				border-top: 1px solid $green;
				border-bottom: 1px solid $green;
				a {
					color: $green;
				}
			}
		}
	}
	.quicktabs_main.quicktabs-style-navlist {
		width: 85%;
		background-color: none;
		border: 1px solid $green;
	}
}