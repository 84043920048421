/* ==========================================================================
   Messages
   ========================================================================== */

.messages {
  margin: 1em 0;
  padding: 10px 10px 10px 50px; /* LTR */
  background-position: 8px 8px; /* LTR */
  background-repeat: no-repeat;
  border-width: 1px;
  border-style: solid;
}
.messages--status {
  @extend .messages;
  @extend %ok;
  background-image: image-url("message-24-ok.png");
  border-color: #be7;
}
.messages--warning {
  @extend .messages;
  @extend %warning;
  background-image: image-url("message-24-warning.png");
  border-color: #ed5;
}
.messages--error {
  @extend .messages;
  @extend %error;
  background-image: image-url("message-24-error.png");
  border-color: #ed541d;

}
.messages__list {
  margin: 0;
}
.messages__item {
  list-style-image: none;
}

/* Core/module installation error messages. */
.messages--error  p.error {
  color: #333;
}

/* System status report. */
.ok,
.status,
%ok {
  background-color: #f8fff0;
  color: #234600;
}
.warning,
%warning {
  background-color: #fffce5;
  color: #840;
}
.error,
%error {
  background-color: #fef5f1;
  color: #8c2e0b;
}