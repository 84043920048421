/* ==========================================================================
   Default Link Styles
   ========================================================================== */


a {
  color: $color-link;
  text-decoration: none;

  -webkit-transition: color 0.5s ease, border-color 0.5s ease;
  -moz-transition: color 0.5s ease, border-color 0.5s ease;
  -o-transition: color 0.5s ease, border-color 0.5s ease;
  transition: color 0.5s ease, border-color 0.5s ease;

  &:hover,
  &:focus {
    color: $color-link-hover;
  }
}